import logo from '../../assets/imageVetorDex.png';
import './Contato.css';
import React, { useState } from "react";
import { Link } from "react-router-dom";
import api from '../../services/api'

function Contato() {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  function submitEmail(event) {
    event.preventDefault();
    const emailToSend = {
      name,
      email,
      message
    }

    api.post('/email', emailToSend)
    setName("");
    setEmail("");
    setMessage("");
  }


  return (
    <div id='contato'>
      <div className='background'>
      </div>
      <main className="App-header">
        {/* <a className="btn-menu" href='login' onClick={handleClickLogin} style={{ position: 'absolute', zIndex: 10, top: 0, right: 10 }}>Login</a> */}
        {/* <a className="btn-menu" href='login' style={{ position: 'absolute', zIndex: 10, top: 0, right: 10 }}>Login</a> */}
        <Link className="btn-menu" style={{ position: 'absolute', zIndex: 10, top: 0, left: 10 }} to="/privacidade">Política de Privacidade</Link>
        <Link className="btn-menu" style={{ position: 'absolute', zIndex: 10, top: 0, right: 10 }} to="/login">Login</Link>


        <form id="contact-form" method="POST" onSubmit={submitEmail}>
          <h1>Suporte</h1>
          <span id="text">Dúvidas, críticas ou sugestões? Envie-nos um e-mail!</span>
          <div className="form-group">
            <label htmlFor="name">Nome</label>
            <input type="text" className="form-control" value={name} onChange={e => setName(e.target.value)} />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input type="email" className="form-control" aria-describedby="emailHelp" value={email} onChange={e => setEmail(e.target.value)} />
          </div>
          <div className="form-group">
            <label htmlFor="message">Mensagem</label>
            <textarea className="form-control" rows="8" value={message} onChange={e => setMessage(e.target.value)} />
          </div>
          <button type="submit" className="form-btn">Enviar</button>
        </form>
        <div className='footer'>
          <h3>VetorDex</h3>
          <img src={logo} className="App-logo" alt="logo" />
        </div>
      </main>
    </div >
  );


}

export default Contato;