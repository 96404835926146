import React from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import Contato from './pages/Contato/Contato'
import Login from './pages/Login/Login'
import EnvioArquivos from './pages/EnvioArquivos/EnvioArquivos'
import PoliticaDePrivacidade from './pages/PoliticaDePrivacidade/PoliticaDePrivacidade'
// import NotFound from '../NotFound/NotFound'


// Componente: Bloco isolado de HTML, CSS e JS, o qual não interfere no restante da aplicação
// é uma função que retorna um html
// Propriedade: Informações que um componente PAI passa para o componente FILHO
// Estado: Informações mantidas pelo componente (imutabilidade)

function App() {

  function verifyIfIsAuthenticated() {
    if (sessionStorage.getItem('token')) {
      return true
    } else {
      return false
    }
  }

  function PrivateRoute({ children, ...rest }) {

    return (
      <Route
        {...rest}
        render={({ location }) =>
          verifyIfIsAuthenticated() ? (
            children
          ) : (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: location }
                }}
              />
            )
        }
      />
    );
  }

  return (
    <Router>
      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/contato">
          <Contato />
        </Route>
        <Route path="/privacidade">
          <PoliticaDePrivacidade />
        </Route>
        <PrivateRoute exact path="/arquivos">
          <EnvioArquivos />
        </PrivateRoute>
        <Route path='*'>
          <Contato/>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;