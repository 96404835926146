import React, { useState, useEffect } from 'react';

import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Link } from "react-router-dom";




//css MIkha
import LogoVetorDex from '../../assets/imageVetorDex.png';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import Alert from "@material-ui/lab/Alert";
import Collapse from '@material-ui/core/Collapse';

import { useHistory } from 'react-router-dom';


import api from '../../services/api'


import './Login.css'




/*
 const validationMethods = {
  required: (field, value) => {
    if (!value.toString().trim().length) {
      return `This ${field} field is required.`
    }
  },
  isEmail: (field, value) => {
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (reg.test(value) === false) {
      return `Invalid Email Address.`
    }
  }
}
const validateForm = (form) => {
  const loginForm = document.getElementById(form)
  return loginForm.querySelectorAll('[validations]');
}
const runValidationRules = (element, errors) => {
  const target = element;
  const field = target.name;
  const value = target.value
  let validations = element.getAttribute('validations');
  validations = validations.split(',')
  for (let validation of validations) {
    validation = validation.split(':');
    const rule = validation[0];
    const error = validationMethods[rule](field, value);
    errors[field] = errors[field] || {};
    if (error) {
      errors[field][rule] = error;
    } else {
      if (field.isEmpty(errors[field])) {
        delete errors[field];
      } else {
        delete errors[field][rule];
      }
    }
  }
  return errors;
}
  */
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '18ch',
    display: 'flex',
    alignSelf: 'center',
    margin: theme.spacing(1),
  },
}));

function Login({ from }) {

  const [open, setOpen] = useState(false);

  useEffect(() => {

    let token = sessionStorage.getItem('token');

    if (token) {
      history.replace('/arquivos')
    }

  }, []);

  const history = useHistory()
  const classes = useStyles();
  const [values, setValues] = useState({
    username: '',
    password: '',
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };




  const handleSubmit = async (event) => {
    console.log(history)
    event.preventDefault();
    let user = {
      username: values.username,
      password: values.password
    }
    console.log(user)
    await api.post('/users/signin', user)
      .then(response => {
        if (response.status === 200) {
          sessionStorage.clear()
          sessionStorage.setItem('token', response.data.token)
          sessionStorage.setItem('user', user.username)
          //history.push('/')
          history.replace(from);
        } else {
          setOpen(true)
        }
      })
      .catch(error => {
        alert(error)
        //  alert('Erro no login')
        // setOpen(true)
        // console.log(setOpen)
      })
    history.push("/arquivos");
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <div className='login-container'>
      <Link className="btn-menu" style={{ position: 'absolute', zIndex: 10, top: 0, left: 10 }} to="/contato">Contato</Link>
      <div className='login-background'>
      </div>
      <form className='login-form-container' onSubmit={handleSubmit}>
        <div className='login-div-logo'>
          <h1>Login</h1>
        </div>

        <FormControl styles={{ backgroundColor: "white" }} className={classes.textField} variant="filled">

          <br></br>
          <TextField id="outlined-basic"
            value={values.username} onChange={handleChange('username')} label="Usuário" variant="filled"
          />
        </FormControl>
        <FormControl className={clsx(classes.textField)} variant="filled">
          <InputLabel color="secondary" htmlFor="outlined-adornment" >Senha</InputLabel>
          <FilledInput
            id="outlined-adornment-password"
            type={values.showPassword ? 'text' : 'password'}
            value={values.password}
            onChange={handleChange('password')}
            endAdornment={
              <InputAdornment position="end" filled="true" color="primary" className='eyeButton'>
                <IconButton
                  variant="filled"
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          // labelWidth={70}
          />
        </FormControl>
        <FormControl className="login-divButton" variant="outlined">
          <button type="submit" className="login-ButtonForm">ACESSAR</button>
          <Collapse className="meuCollapse" in={open} onClose={handleClose}>
            <Alert className="meuAlert" onClose={handleClose} severity="error" variant="outlined" >
              Usuário e/ou senha inválidos!
            </Alert>
          </Collapse>
        </FormControl>

      </form>
      <div className='footer'>
        <h3>VetorDex</h3>
        <img alt='Logo VetorDex' src={LogoVetorDex} className='LogoVetorDex' />
      </div>
    </div>

  );
}
/*
  */
//// />
export default Login;