import React, { useState } from 'react';
import logo from '../../assets/imageVetorDex.png'
import api from '../../services/api'
import { Link } from 'react-router-dom'

import './EnvioArquivos.css'

function EnvioArquivos() {

    const [fileXLSName, setXLSFileName] = React.useState("");
    const [disabledUploadImages, setDisabledUploadImages] = React.useState(true);
    const [blockSubmit, setBlockSubmit] = React.useState(true);
    const [isLoading, setIsLoading] = useState(false);


    //    const [imageFiles, setImageFiles] = React.useState([]);
    const [imagesToUpload, setImagesToUpload] = React.useState([]);

    async function submitFile(e) {
        e.preventDefault();

        const user = sessionStorage.getItem('user');
        setIsLoading(true)
        setBlockSubmit(true)
        await api.post('/upload/uploadSubmit', { 'user': user })
            .then((response) => {
                window.alert(response.data.message)
                setIsLoading(false)
            }).catch((error) => {
                console.log(error)
            })
        window.location.reload();
    }

    function verifySubmitButton(images) {
        console.log("verifySubmitButton")
        //verifica se tem alguma imagem faltando a ser upada para desabilitar/habilitar o botão de enviar
        console.log(imagesToUpload, "VERIFY BUTTON IMAGES TO UPLOAD ")
        for (let index = 0; index < images.length; index++) {
            const element = images[index];
            console.log(element, "ELEMENT")
            if(element.color === "#E02041"){
                setBlockSubmit(true)
                return
            }
        }
        setBlockSubmit(false);
    }

    async function verifyImages(user, imagesJson) {
        // const user = sessionStorage.getItem('user');
        console.log("USER VERIFY IMs", user)

        const images = await api.get(`/upload/getImageList/?user=${user}`)
            .then((response) => {
                // retira primeiro elemento
                // response.data.imagesFromBackend.shift()
                let imageList = response.data.imagesFromBackend || []
                // trata a string pra ficar só o nome do arquivo

                let images = [];
                // item = {image, color}
                for (let index = 0; index < imagesJson.length; index++) {
                    const item = imagesJson[index];
                    if (imageList.find((itemFirebase) => {
                        let indexPonto = itemFirebase.indexOf('.')
                        const nameImageFirebase = itemFirebase.slice(0, indexPonto);
                        return (nameImageFirebase === item.image)
                    })) {
                        item.color = "#2ee59d"
                    }
                    images.push(item);
                }
                console.log(images, "images")
                images.sort((a, b) => {
                    if(a.color > b.color){
                        return -1
                    }
                    if(a.colobr < b.color){
                        return 1
                    }
                    return 0
                })
                return images
                
            })
            return images
    }

    async function handleXLSSelect(e) {
        e.preventDefault();
        setIsLoading(true)
        

        console.log('handleXLSSelect')
        if (e.target.files.length !== 0) {
            let file = e.target.files[0];

            setXLSFileName(e.target.files[0].name)
            const user = sessionStorage.getItem('user');

            const formData = new FormData();
            formData.append('fileXls', file);
            formData.append('user', user);

            const imageList = await api.post('/upload/xls', formData)
                .then(response => {
                    setDisabledUploadImages(false)
                    // animação
                    document.getElementById('hidden-div').style.opacity = 1;
                    document.getElementById('hidden-div').style.transform = 'translateY(0px)';
                    document.getElementById('hidden-div').style.height = 'auto';
                    setIsLoading(false)
                    //

                    let imagesJson = response.data.images;
                    // imagesJson.shift();
                    const images = imagesJson.map((item) => {
                        return { image: item, color: "#E02041" }
                    })
                    return images;
                }).catch(error => {
                    console.log("*****  " + error)
                });
            
            const images = await verifyImages(user, imageList)
            console.log(images, "images2")
            console.log(imagesToUpload)
            setImagesToUpload(images)
            console.log(imagesToUpload)
            verifySubmitButton(images)
        } else {
            setIsLoading(false)
            // setImagesToUpload([])
            setXLSFileName("")
        }

        // verifySubmitButton()
    }

    async function handleImageSelect(e){
        e.preventDefault();
        let selectedFiles = e.target.files
        if (selectedFiles.length !== 0) {
            console.log(e.target.files)

            const user = sessionStorage.getItem('user');
            const imagesFound = []
            for (let index = 0; index < selectedFiles.length; index++) {
                // remove extension
                const imageName = selectedFiles[index].name.replace(/\.[^.]*$/, '');

                if (imagesToUpload.find((item) => item.image === imageName)) {
                    const image = selectedFiles[index];
                    // imageFiles.push(image)

                    let formData = new FormData();
                    formData.append('file', image);
                    formData.append('user', user);

                    console.log(formData)

                    await api.post('/upload/image', formData)
                        .then((response) => {
                            imagesFound.push(imageName)
                            console.log(response)
                        }).catch((error) => {
                            console.error(error)
                        })
                } else {
                    let stringError = `A imagem "${imageName}" não está relacionada na planilha.`
                    window.alert(stringError)
                }
            }
            let images = []
            for (let index = 0; index < imagesToUpload.length; index++) {
                const item = imagesToUpload[index];
                if (imagesFound.find((image) => {
                    return (image === item.image)
                })) {
                    item.color = "#1E87FF"
                }
                images.push(item);
            }

            images.sort((a, b) => {
                if(a.color > b.color){
                    return -1
                }
                if(a.colobr < b.color){
                    return 1
                }
                return 0
            })

            setImagesToUpload(images)
            verifySubmitButton(images)
        }
    }

    return (
        <div className="Arquivos">


            <div className='arquivos-background' />
            <main className="arquivos-App-header">
                <Link className="btn-menu" style={{ position: 'absolute', zIndex: 10, top: 0, right: 10 }} to="/contato">Contato</Link>
                
                <form id="contact-form" method="POST" onSubmit={submitFile} /*enctype="multipart/form-data"*/>
                    <div className='form-header'>
                        <h1>Envio de Arquivos</h1>
                    </div>
                    <div className='form-main'>
                        <span>Selecione a planilha, as respectivas imagens e clique em enviar.</span>
                        <div id='input-xls-div'>
                            <label htmlFor="inputXlsFile">Selecione a planilha</label>
                            <input type="file"
                                id="inputXlsFile"
                                name="inputXlsFile"
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                onChange={handleXLSSelect} />
                            <span id='file-name'>{fileXLSName}</span>
                        </div>
                        <div id='input-images-div'>
                            <label disabled={disabledUploadImages} id='labelinputImageFile' htmlFor="inputImageFile">Selecione as imagens</label>
                            <input type="file"
                                disabled={disabledUploadImages}
                                id="inputImageFile"
                                name="inputImageFile"
                                accept="image/png, image/jpeg, image/gif"
                                multiple
                                onChange={handleImageSelect} />
                        </div>

                        {isLoading === true ?
                            <img
                                alt="loading..."
                                style={{ alignSelf: 'center', height: '40px', width: '40px' }}
                                src={require('../../assets/RippleLutzo.gif')}
                            />
                            :
                            <></>
                        }

                        <div id='hidden-div' className='hidden-div'>
                            <span style={{ margin: '20px 0 4px 0' }}>Imagens esperadas pela planilha enviada:</span>
                            <div className='images-to-upload'>
                                {
                                    imagesToUpload.map((item, id) => {
                                        
                                        return <li id={item.image} className='list-items' style={{ color: item.color }} key={id}>{item.image}</li>
                                    })
                                }
                            </div>
                            <div className='legendas'>
                                <span><span style={{ color: '#2ee59d' }}>•</span> Imagens que já estão no banco</span>
                                <br></br>
                                <span><span style={{ color: '#E02041' }}>•</span> Imagens que  <b>NÃO</b> estão no banco</span>
                                <br></br>
                                <span><span style={{ color: '#1E87FF' }}>•</span> Imagens que serão inseridas ou substituídas</span>
                            </div>
                        </div>

                    </div>
                    <div className='form-footer'>
                        <button disabled={blockSubmit} type="submit" className="btn btn-primary">Enviar</button>
                    </div>
                </form>

                <div className='arquivos-footer'>
                    <h3>VetorDex</h3>
                    <img src={logo} className="arquivos-App-logo" alt="logo" />
                </div>
            </main>
        </div >
    );
}

export default EnvioArquivos;